<template>
    <div class="modal-profile-item-selector edit-profile-detail items-start">
        <div class="back-button-wrapper" @click="onClickClose">
            <!-- <i class="back-button material-icons">{{ options.icon || 'chevron_left' }} </i> -->
            <img
                class="m-r-2 back-button"
                :src="require(`@/assets/images/icons/${options.icon || 'back'}.png`)"
                width="24px"
                height="24px"
            />

            <span v-if="options.headerTitle" class="f-18 m-l-12 c-black">{{ options.headerTitle }}</span>
        </div>
        <div class="title" :class="{ 'm-b-16': moneyInputAllowed }" v-html="title" />
        <div v-if="showSearch && options.model !== 'region'" class="input-wrapper items-center">
            <img class="m-r-4" :src="require('@/assets/images/icon-search.png')" alt="" />
            <input
                ref="searchValue"
                v-model="searchValue"
                @input="onKeydown"
                :placeholder="$translate(`PLACEHOLDER_SEARCH_${options.model.toUpperCase()}`)"
            />
            <i v-if="searchValue" @click="searchValue = ''" class="material-icons">cancel</i>
        </div>
        <div class="no-search-result m-l-20 flex-wrap" v-if="noResult">
            <i class="material-icons-outlined c-danger m-r-4">info</i>
            <span
                v-html="
                    options.model === 'station' ? $translate('NO_SEARCH_RESULT_STATE') : $translate('NO_SEARCH_RESULT')
                "
            />
        </div>
        <ul class="result flex-fill" :class="{ 'grid-col': displayGrid }">
            <li
                @click="onClickItem(item)"
                class="item flex-row items-center flex-between"
                :class="{ selected: item.$$selected }"
                :key="item.id"
                v-for="item in profileItems"
            >
                <div v-html="$translate(getVal(item))" />
                <!-- <i class="material-icons i-right m-r-0" v-if="getVal(item)">chevron_right</i> -->
                <img
                    v-if="getVal(item)"
                    class="i-right"
                    :src="require(`@/assets/images/icons/arrow_right.svg`)"
                    width="16px"
                    height="16px"
                />
            </li>
        </ul>
        <div class="p-16">
            <DigitInput v-if="heightInputAllowed" @input="onChangeNumberVal" :initialVal="getNumberVal()" />
            <div v-if="moneyInputAllowed">
                <div class="guide">
                    <div class="text flex-row" v-for="i in [1, 2]" :key="i">
                        <p class="dot m-r-8 m-b-3" />
                        <span v-html="$translate(`SIGNUP_${options.model.toUpperCase()}_GUIDE_${i}`)" />
                    </div>
                </div>
                <MoneyInput
                    v-if="moneyInputAllowed"
                    @input="onChangeNumberVal"
                    @focus="needAddInfo = false"
                    @blur="needAddInfo = false"
                    :initialVal="getNumberVal()"
                />
            </div>
        </div>
        <BottomButton
            :label="`저장`"
            v-if="heightInputAllowed || moneyInputAllowed || chooseOneMoreAllowed"
            :needAddInfo="moneyInputAllowed && numberValue"
            @click="onClickClose"
            :disabled="buttonDisabled"
        />
    </div>
</template>

<script>
import debounce from '@/modules/debounce'
import profileService from '@/services/profile'

export default {
    name: 'ModalProfileItemSelector',
    props: ['options'],
    data: () => ({
        profileItems: [],
        showSearch: true,
        searchValue: '',
        numberValue: 0,
        buttonDisabled: false,
    }),
    watch: {
        searchValue(newVal) {
            if (!newVal) this.search()
        },
    },
    computed: {
        essentialFunnel() {
            const fullPath = this.$route.fullPath.split('/')
            return fullPath.includes('signup')
        },
        section() {
            return this.$sections()
        },
        findCategory() {
            if (['station', 'region'].includes(this.options.model)) {
                return 'Personal'
            }

            let res = ''
            for (let i = 0; i < this.section.length; i++) {
                if (this.section[i].column.includes(this.options.model)) {
                    res = this.section[i].title
                    break
                }
            }

            return res
        },
        userInputAllowed() {
            return (
                [
                    'job',
                    'jobType',
                    'company',
                    'university',
                    'college',
                    'major',
                    'major',
                    'high',
                    'company_department',
                    'company_task',
                ].indexOf(this.options.model) !== -1
            )
        },
        noResult() {
            if (this.options.model === 'station') {
                return this.profileItems.length === 0
            }

            return (
                this.showSearch &&
                this.searchValue &&
                this.profileItems.length <= 1 &&
                ['high', 'company_department', 'company_task'].indexOf(this.options.model) === -1
            )
        },
        moneyInputAllowed() {
            return ['asset', 'income', 'family_asset'].indexOf(this.options.model) !== -1
        },
        heightInputAllowed() {
            return ['height'].indexOf(this.options.model) !== -1
        },
        endpoint() {
            return this.$case.pluralize(this.options.model)
        },
        params() {
            let params = { name: this.searchValue }
            if (this.options.model === 'company')
                params.jobCategoryId = ((this.options.profile || {}).job_category || {}).id || 1
            if (this.options.model === 'university')
                params.schoolTypeId = ((this.options.profile || {}).school_type || {}).id || {
                    schoolTypeId: 7,
                    name: '',
                }
            if (this.options.model === 'station') params.region = this.options.profile.state.name
            if (this.options.model === 'region') {
                params = { name: this.options.profile.state.name, district: this.searchValue }
            }

            // if (['exercise_freq','exercise_type','drink_freq','drink_style','house_style','house_payment','marry_plan','child_plan','career_plan'].indexOf(this.options.model) !== -1) {
            //     params[`${this.$case.toCamel(this.options.model)}`] = this.options.profile[`${this.options.model}`]
            // }
            if (this.options.model === 'exercise_freq') params.exerciseFreq = this.options.profile.exercise_freq
            if (this.options.model === 'exercise_type') params.exerciseType = this.options.profile.exercise_type
            if (this.options.model === 'drink_freq') params.drinkFreq = this.options.profile.drink_freq
            if (this.options.model === 'drink_style') params.drinkStyle = this.options.profile.drink_style

            if (this.options.model === 'house_style') params.houseStyle = this.options.profile.house_style
            if (this.options.model === 'house_payment') params.housePayment = this.options.profile.house_payment
            if (this.options.model === 'marry_plan') params.marryPlan = this.options.profile.marry_plan
            if (this.options.model === 'child_plan') params.childPlan = this.options.profile.child_plan
            if (this.options.model === 'career_plan') params.careerPlan = this.options.profile.career_plan

            return params
        },
        title() {
            if (this.options.model === 'university' || this.options.model === 'company') {
                return this.$translate(this.$case.toConst(`${this.options.model}_input_title`))
            }

            return this.$translate(this.$case.toConst(this.options.model))
        },
        displayGrid() {
            return (
                ['kind', 'mbti', 'pet', 'religion', 'car', 'drink_style', 'exercise_type', 'drink_style'].indexOf(
                    this.options.model
                ) > -1
            )
        },
        showDesc() {
            return ['kind', 'mbti', 'car'].indexOf(this.options.model) > -1
        },
        desc() {
            if (!this.showDesc) return

            if (this.options.model === 'kind') {
                return '<span class="f-bold c-primary">2가지</span>를 선택해주세요'
            }

            if (this.options.model === 'mbti') {
                return '<span class="f-bold c-primary">성격유형(MBTI)</span>를 선택해주세요'
            }

            if (this.options.model === 'car') {
                return '<span class="f-bold c-primary">미보유</span>를 선택한 경우 프로필에 노출되지 않습니다'
            }

            return ''
        },
        chooseOneMoreAllowed() {
            return ['drink_style', 'pet', 'exercise_type'].includes(this.options.model)
        },
    },
    mounted() {
        this.showSearch = ['region', 'station'].indexOf(this.options.model) !== -1 || this.userInputAllowed

        if (this.options.model === 'smoking') {
            this.profileItems = [{ name: '비흡연' }, { name: '흡연' }]
            return
        }

        if (this.options.model === 'gender') {
            this.profileItems = [{ name: 'FEMALE' }, { name: 'MALE' }]
            return
        }

        if (this.options.model === 'car') {
            this.profileItems = [{ name: '보유' }, { name: '미보유' }]
            return
        }

        if (this.options.model === 'mbti') {
            this.profileItems = [...this.$store.getters.mbti]
            return
        }

        if (this.options.model === 'pet') {
            this.profileItems = [...this.$store.getters.pet]
            return
        }

        if (this.options.model === 'age') {
            this.profileItems = [
                { id: 0, name: '아니요, 초혼입니다.', next: false, $$selected: true },
                { id: 1, name: '네, 재혼입니다.', next: true },
            ]
            return
        }

        if (this.options.model === 'marry') {
            this.profileItems = [
                { id: 0, name: '초혼입니다.', next: false },
                { id: 1, name: '돌싱입니다.', next: true },
            ]
            return
        }

        if (
            [
                'exercise_freq',
                'exercise_type',
                'drink_freq',
                'drink_style',
                'house_style',
                'house_payment',
                'marry_plan',
                'child_plan',
                'career_plan',
            ].indexOf(this.options.model) !== -1
        ) {
            this.profileItems = [...this.$store.getters[`${this.$case.toCamel(this.options.model)}`]]
            return
        }

        if (this.moneyInputAllowed || this.heightInputAllowed) {
            this.numberValue = this.options.profile[`${this.options.model}`]
        }

        if (!this.showSearch) {
            this.profileItems = this.$store.getters[this.$case.pluralize(this.options.model)]
            // 학력: 대학교 재학 이상일 때 학교구분: 고등학교 졸업 삭제
            if (this.options.model === 'schoolType' && this.options.profile.school.id !== 5) {
                const temp = this.profileItems.filter(item => {
                    if (item.id !== 14) return item
                })
                this.profileItems = temp
            }

            if (this.options.model === 'shape' || this.options.model === 'kind') {
                const gender = this.$store.getters.me.gender

                if (typeof gender === 'number') {
                    this.profileItems = this.profileItems.filter(item => item.gender === gender)
                } else {
                    this.profileItems = this.profileItems.filter(
                        item => item.gender === (gender.name === 'MALE' ? 0 : 1)
                    )
                }
            }
            return
        }

        this.search()
    },
    methods: {
        onChangeNumberVal(val) {
            this.buttonDisabled = this.options.model === 'height' ? !(Number(val) > 140 && Number(val) < 200) : false
            this.numberValue = val
        },
        getNumberVal() {
            return parseInt(this.numberValue)
        },
        search: debounce(async function () {
            try {
                if (['high', 'company_department', 'company_task'].indexOf(this.options.model) !== -1) {
                    this.profileItems = [{ name: this.searchValue }]
                } else {
                    const res = await profileService[this.endpoint](this.params)
                    if (['job', 'company'].includes(this.options.model)) {
                        this.profileItems = this.searchValue ? [{ name: this.searchValue }, ...res] : [...res]
                    } else {
                        this.profileItems = [...res]
                    }

                    this.sortRegionAndStation() // 지역, 지하철역 오름차순 정렬
                    if (this.profileItems.length === 0 && this.userInputAllowed) {
                        this.profileItems = [{ name: this.searchValue }]
                    }
                }
            } catch (e) {
                console.log(e)
                this.$toast.error(e.data)
            }
        }, 200),
        onKeydown() {
            setTimeout(() => {
                this.searchValue = this.$refs.searchValue.value
                this.search(this.searchValue)
            })
        },
        getVal(o) {
            if (this.userInputAllowed && !o.id) {
                return `'${this.searchValue}'로 입력하기`
            }

            return this.options.model === 'region' ? o.district : o.name
        },
        onClickItem(item) {
            if (this.options.model === 'pet') {
                item.name === '키우지않아요'
                    ? this.profileItems.forEach(o => this.$set(o, '$$selected', false))
                    : this.$set(
                          this.profileItems.find(o => o.name === '키우지않아요'),
                          '$$selected',
                          false
                      )
            }

            this.$set(item, '$$selected', !item.$$selected)

            setTimeout(() => {
                if (this.options.model === 'kind') {
                    const selected = this.profileItems.filter(o => o.$$selected)
                    if (selected.length < 2) return

                    this.$emit(
                        'close',
                        this.profileItems.filter(o => o.$$selected)
                    )
                } else if (!this.chooseOneMoreAllowed) {
                    this.$emit('close', item)
                }

                if (!this.chooseOneMoreAllowed) {
                    this.profileItems.forEach(o => this.$set(o, '$$selected', false))
                }
            }, 200)
        },
        sortRegionAndStation() {
            if (this.options.model === 'region') {
                this.profileItems = this.profileItems.sort(function (a, b) {
                    return a.district < b.district ? -1 : a.district > b.district ? 1 : 0
                })
            }

            if (this.options.model === 'station') {
                this.profileItems = this.profileItems.sort(function (a, b) {
                    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                })
            }
        },
        onClickClose() {
            if (this.chooseOneMoreAllowed) {
                // 중복 선택 가능
                this.$emit(
                    'close',
                    this.profileItems.filter(o => o.$$selected)
                )
                this.profileItems.forEach(o => this.$set(o, '$$selected', false))
            } else if (this.moneyInputAllowed || this.heightInputAllowed) {
                this.$emit('close', this.numberValue)
            } else {
                this.$emit('close')
            }
        },
    },
}
</script>
